﻿import { FunctionComponent, ReactNode } from "react";
import { Box, Flex, FlexProps, Heading, Image, Text } from "@chakra-ui/react";

interface ApplicationLayoutProps {
    contentHeader?: ReactNode;
    content?: ReactNode;
    contentContainerProps?: FlexProps;
    buttons?: ReactNode;
}

export const ApplicationLayout: FunctionComponent<ApplicationLayoutProps> = ({ contentHeader, content, contentContainerProps, buttons }) => {
    return (
        <>
            <Flex direction="column" h={{ base: "100vh", md: "unset" }}>
                <Box
                    boxShadow={contentHeader ? "" : "0 5px 5px rgba(0,0,0,.1)"}
                    bgGradient={"linear(to-r, blue.900, blue.500)"}
                    position={{ base: "unset", md: "sticky" }}
                    top={{ base: "unset", md: 0 }}
                    zIndex={{ base: "unset", md: 1 }}
                    px={"2rem"}
                >
                    <Flex
                        as={"header"}
                        w={{ base: "100%", md: "calc(48em - 4rem)" }}
                        mx={{ base: "unset", md: "auto" }}
                        height={"100px"}
                        flexShrink={0}
                        justifyContent={"start"}
                        alignItems={"center"}
                        gap={".5rem"}
                    >
                        <Box flexShrink={0}>
                            <Image src={"/acclaim_logo.svg"} boxSize={{ base: 55, md: 65 }} />
                        </Box>
                        <Heading m={0} flexShrink={1}>
                            <Text
                                fontFamily="Helvetica Neue LT Std Black"
                                color={"white"}
                                whiteSpace={"nowrap"}
                                textTransform={"uppercase"}
                                fontSize={{ base: ".75rem", md: "1rem" }}
                                letterSpacing={{ base: ".25rem", md: ".5rem" }}
                                mb={{ base: "0", md: ".2rem" }}
                            >
                                Acclaim Tax
                            </Text>
                            <Text
                                fontFamily="Helvetica Neue LT Std Light"
                                color={"white"}
                                whiteSpace={"nowrap"}
                                textTransform={"uppercase"}
                                fontSize={{ base: "1rem", md: "1.25rem" }}
                                letterSpacing={{ base: ".03rem", md: ".08rem" }}
                            >
                                Work Opportunity Tax Credit
                            </Text>
                        </Heading>
                    </Flex>
                </Box>

                {contentHeader && (
                    <Box
                        py={"1rem"}
                        px={"2rem"}
                        boxShadow={"0 5px 5px rgba(0,0,0,.1)"}
                        position={{ base: "unset", md: "sticky" }}
                        top={{ base: "unset", md: 100 }}
                        zIndex={{ base: "unset", md: 1 }}
                        bg={"white"}
                    >
                        <Box w={{ base: "100%", md: "calc(48em - 4rem)" }} mx={{ base: "unset", md: "auto" }}>
                            {contentHeader}
                        </Box>
                    </Box>
                )}

                {content && (
                    <Box flexGrow={1} overflowY={"auto"} px={"2rem"}>
                        <Flex
                            as={"main"}
                            w={{ base: "100%", md: "calc(48em - 4rem)" }}
                            mx={{ base: "unset", md: "auto" }}
                            direction="column"
                            py={"2rem"}
                            gap="2rem"
                            {...contentContainerProps}
                        >
                            {content}
                        </Flex>
                    </Box>
                )}

                {buttons && (
                    <Box flexShrink={0} px={"2rem"} boxShadow={{ base: "0 -5px 5px rgba(0,0,0,.1)", md: "unset" }}>
                        <Flex w={{ base: "100%", md: "calc(48em - 4rem)" }} mx={{ base: "unset", md: "auto" }} py={"1rem"} justifyContent={"space-between"}>
                            {buttons}
                        </Flex>
                    </Box>
                )}
            </Flex>
        </>
    );
};
