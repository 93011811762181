﻿import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Heading, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import { ApplicationLayout } from "../../components/ApplicationLayout";
import { useConfig, useLogin } from "../../api/apiQueries";
import { useTranslation } from "react-i18next";
import { useHandleLoginResponse } from "../../hooks/useHandleLoginResponse";
import { useLoginRequest } from "../../hooks/useLoginRequest";
import { useSqId } from "../../context/SqIdContext";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const IntroPage: FunctionComponent<{ fixed?: boolean }> = () => {
    const sqId = useSqId();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const appInsights = useAppInsightsContext();
    const configQuery = useConfig();
    const config = configQuery.data;

    //eager fetch the next page in the series but do it after the page has loaded off the main thread
    useEffect(() => {
        const timeout = setTimeout(() => {
            import("../CandidateDetailsPage");
        }, 250);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/questionnaire/intro",
            name: "Questionnaire: Intro",
        });
    }, []);

    const toggleLanguage = () => {
        appInsights.trackEvent({
            name: "Change language",
            properties: {
                oldLanguage: i18n.language,
                newLanguage: i18n.language === "es" ? "en" : "es",
            },
        });

        i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
    };

    const loginRequest = useLoginRequest();
    const loginQuery = useLogin();
    const handleLoginResponse = useHandleLoginResponse();
    const login = async () => {
        const response = await loginQuery.mutateAsync(loginRequest);
        handleLoginResponse(response);
    };

    const optOut = () => {
        navigate(`/${sqId}/optOut`);
    };

    return (
        <ApplicationLayout
            contentContainerProps={{
                minHeight: { base: "100vh", md: "unset" },
            }}
            content={
                <>
                    <Heading>{t("intro.heading", { clientName: config?.clientName })}</Heading>
                    <Text>{t("intro.text")}</Text>
                    <Text>{t("intro.text2")}</Text>
                    {config?.introText && <Text>{config.introText}</Text>}
                    <Button mt="2rem" flexShrink={0} variant={"primary"} isLoading={loginQuery.isLoading} onClick={login}>
                        {t("intro.startButton")}
                    </Button>
                    <Button flexShrink={0} onClick={toggleLanguage}>
                        {t("intro.languageToggle")}
                    </Button>
                    <Spacer display={{ md: "none" }} />
                    <Button display={{ md: "none" }} flexShrink={0} variant={"ghost"} onClick={optOut} size={"md"}>
                        {t("intro.optOut")}
                    </Button>
                    <Menu>
                        <MenuButton as={Button} display={{ base: "none", md: "unset" }} variant={"ghost"}>
                            {t("intro.moreOptions")}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={optOut}>{t("intro.optOut")}</MenuItem>
                        </MenuList>
                    </Menu>
                    {config?.introLegal && <Text size={"sm"}>{config.introLegal}</Text>}
                </>
            }
        />
    );
};
